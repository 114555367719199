.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  border: 2px solid black;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalMessage {
  font-size: 14px;
  margin-bottom: 20px;
  color: red;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

.modalButton {
  padding: 8px 16px;
  background-color: #1468A6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: auto;
  width: 50%;
  background-color: #ff8000;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  transition: background-color 0.3s ease;
}

.modalButton:hover {
  background-color: #ff6600;
}