.email-update {
    margin: auto;
    max-width: 1140px;
}

.email-update .ant-card .ant-card-body {
    padding-left: 0px;
}

@media (min-width: 769px) and (max-width: 1024px) {
    .email-update {
        margin-left: 42px;
    }
}

@media (max-width: 480px) {
    .email-update {
            width: auto;
    }

    .email-update .ant-card .ant-card-body {
        padding-left: 24px;
    }
}