.text-underline {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

.global-footer {
  background-color: #294c7c;
  color: #fff;
  position: relative;
}

.global-footer .footer-title {
  color: rgb(255, 255, 255) !important;
  font-size: 19px !important;
  font-family: GlacialInDifference !important;
}

.footer-paper-peel {
  background-image: url('https://assets.cure.com/img/main-site/footer-page-peel.png');
  width: 91.2px;
  height: 105px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.global-footer .mobile-footer {
  display: none;
}
.global-footer .desktop-footer {
  padding: 20px;
  padding-bottom: 0px;
}

.text-reset {
  color: rgb(255, 255, 255) !important;
  line-height: 1.5em;
  font-size: large !important;
  font-family: GlacialInDifference !important;
}


.global-footer .text-reset li, .global-footer .text-reset li a {
  color: white!important;
}

.text-reset li, .text-reset li a {
  color: white!important;
}



.text-reset li.mobile {
  display: none;
}

a.social {
  margin-right: 8px;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #fff;
}

a.facebook {
  color: #fff;
}

a.twitter {
  color: #fff;
}

a.instagram {
  color: #fff;
}

.twitter img {
  content: url('https://assets.cure.com/img/main-site/webp/x-logo.webp');
  width: 30px;
  height: 30px;
  display: inherit;
  margin-top: 0.25px;
}

.facebook img {
  content: url('https://assets.cure.com/img/main-site/webp/facebook.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

.youtube img {
  content: url('https://assets.cure.com/img/main-site/webp/youtube.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

.instagram img {
  content: url('https://assets.cure.com/img/main-site/webp/instagram.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

.linkedin img {
  content: url('https://assets.cure.com/img/main-site/webp/linkedin.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

.text-menu {
  color: rgb(255, 255, 255) !important;
  line-height: 1.7em;
  font-size: large !important;
  font-family: GlacialInDifference !important;
}

.global-footer .footer-address {
  text-align: center;
  font-size: 19px;
  text-transform: capitalize;
}

.global-footer .copyright {
  font-size: 19px;
  text-align: center;
  font-family: GlacialInDifference !important;
}

.global-footer .last-btn {
  margin-left: 0 !important;
}

.global-footer .footer-btn-roadside-mobile {
  margin-left: 2px !important;
}

.global-footer .desktop-footer .copyright {
  color: white;
  margin-bottom: 20px;
  margin-left: 0px !important;
}

@media (max-width: 481px) {
  .mobile-footer {
    display: block !important;
  }

  .global-footer {
    display: none !important;
  }

  .global-footer .desktop-footer {
    display: flex !important;
    padding: 0px;
    justify-content: center;
  }

  .global-footer .desktop-footer .copyright {
    justify-content: center;
    display: inline-block;
    margin-bottom: 12px;
    width: 100%;
  }

  .global-footer .desktop-footer .address li {
    display: flex;
    justify-content: center;
  }

  .global-footer .footer-title {
    font-size: small !important;
    margin-left: 5px;
    display: block;
  }
  .text-underline {
    display: inline-block;
  }
  .text-reset {
    font-size: small !important;
  }

  .text-menu {
    font-size: small !important;
  }

  .footer-address {
    text-align: center;
    font-size: small !important;
    text-transform: capitalize;
  }

  .copyright {
    font-size: small !important;
    text-align: center;
    margin-bottom: 10px;
  }

  .social-container {
    margin-left: 5px;
  }

  .global-footer ul {
    margin-top: 12px;
    width: 100%;
  }

  .text-menu {
    font-size: small !important;
  }

  .global-footer {
    padding: 20px 5px;
  }

  .footer-paper-peel {
    width: 48.2px;
    height: 62px;
  }

  .global-footer footer .mobile span {
    font-size: small !important;
  }

  .global-footer footer .footer-btn {
    background: #ff8000;
    border: none;
    color: #fff;
    padding: 4px;
    font-size: 12px;
    width: auto;
  }

  .global-footer footer .footer-btn-roadside {
    margin-left: 4px;
    background: #ff8000;
    border: none;
    color: #fff;
    padding: 4px 4px;
    font-size: 12px;
    width: auto;
    margin-right: 8px;
  }
  .global-footer footer .phone {
    margin-right: 2px;
  }
  .global-footer footer .footer-btn:last-child {
    margin-left: 6px;
    margin-right: 6px;
  }
  a.social {
    margin-right: 24px;
  }
  .text-reset li.desktop {
    display: none;
  }
  .text-reset li.mobile {
    display: inherit;
  }    
}

@media (min-width: 481px) and (max-width: 768px) {
  .global-footer .desktop-footer {
    display: flex !important;
  }

  .global-footer {
    padding: 12px;
  }

  .footer-paper-peel {
    width: 48.2px;
    height: 62px;
  }

  .global-footer .footer-title {
    font-size: large !important;
  }

  .global-footer .footer-address {
    text-align: center;
    font-size: 20px !important;
    text-transform: capitalize;
  }

  .copyright {
    font-size: 20px !important;
    text-align: center;
    margin-bottom: 20px;
  }

  .text-reset {
    font-size: large !important;
  }

  .text-menu {
    font-size: large !important;
  }

  ul li {
    width: 100%;
  }

  .twitter img {
    width: 30px;
    height: 30px;
  }

  .facebook img {
    width: 30px;
    height: 30px;
  }

  .youtube img {
    width: 30px;
    height: 30px;
  }

  .instagram img {
    width: 30px;
    height: 30px;
  }

  .linkedin img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .global-footer .desktop-footer {
    display: flex !important;
  }
  .global-footer .footer-title {
    font-size: 19px !important;
    margin-left: 20px;
  }
  .text-underline {
    font-size: medium !important;
  }
  .text-reset {
    font-size: medium !important;
  }

  .text-menu {
    font-size: medium !important;
  }

  .copyright {
    font-size: 19px;
    text-align: center;
    margin-bottom: 10px;
  }

  .social-container {
    margin-left: 20px;
  }

  a.social {
    margin-right: 8px;
  }

  .twitter img {
    width: 28px;
    height: 28px;
  }

  .facebook img {
    width: 28px;
    height: 28px;
  }

  .youtube img {
    width: 28px;
    height: 28px;
  }

  .instagram img {
    width: 28px;
    height: 28px;
  }

  .linkedin img {
    width: 28px;
    height: 28px;
  }
}
