.not-found-container {
  width: auto;
  background-color: white;
}

.period {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0;
  margin-top: 2px;
  background-image: radial-gradient(rgb(55, 105, 152) 50%, black 100%);
  display: inline-block;
  margin: 2px 2px;
}

.margin-auto {
  margin: auto;
}

.not-found-screen {
  font-family: GlacialInDifference;
  margin: auto;
  width: 1140px;
  margin-top: 0px;
  margin-bottom: 24px;
  min-height: 40vh;
  background-color: white;
}

.not-found-screen .ant-card-bordered {
  border: none;
}

.container-404 {
  margin-bottom: 40px;
}

.container-404 .logo-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin: 40px 0; */
  margin-bottom: 0px;
}

.container-404 .logo-holder img {
  width: 400px;
}

.container-404 span.sub-header-title {
  line-height: 1.4;
}

.container-404 .ButtonCell {
  width: 102px!important;
  margin-right: 1vw;
}
.container-404 .ant-divider-vertical {
  border-left: 2px solid rgb(115, 115, 115);
  height: auto;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.container-404 .mobile-divider-container {
  display: none;
  width: 90%;
}

.container-404 .ant-divider-horizontal {
  border-bottom: 2px solid rgb(115, 115, 115);
  height: auto;
  margin-top: 25px;
  margin-bottom: 0px;
  opacity: 0.50;
}

.container-404  .rsa {
  font-size: 20px;
  color: black!important;
}

.container-404 .start-quote span, .container-404 .sign-in span {
  font-size: 14px;
}

.container-404 .form-inline input {
  height: 45px;
  width: 150px !important;
  background-color: #eee;
  padding: 5% 7%;
  border: solid transparent;
  border-width: 1px;
  line-height: 1.5;
  margin-left: 50px;
  width: 150px !important;
  margin: auto;
  border: 1px solid rgb(55, 105, 152) !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
}

.container-404 .ButtonCell button {
  width: auto;
}

.container-404 .ant-form-inline .ant-form-item {
  margin-right: 0px;
}

.container-404 .past-quote-container {
  padding-top: 12px;
  text-align: center;
  width: 100%;
  margin-bottom: 12px;
}

.container-404 .past-quote {
  color: rgb(55, 105, 152);
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  font-size: .9em;
  text-decoration: underline!important;
  font-weight: 600;
  font-family: "GlacialInDifference";
  letter-spacing: inherit;
  text-decoration: underline;
  font-size: 16px!important;
  font-weight: 300!important;
}

.container-404 button {
  height: 45px;
  /* width: 136px; */
  color: #fff !important;
  background: #ff8000;
  border: none;
  padding: 5% 7%;
  margin-right: auto;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.15625rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 48px;
  font-size: 14px;
}

.container-404 .margin-bottom-18 {
  margin-bottom: 18px !important;
}

.container-404 .get-quote, .container-404 .customer-lnks {
  min-width: 450px;
}

.container-404 .ant-card-head-title {
  text-align: center !important;
  font-weight: 400;
  font-style: normal;
  color: rgb(29, 76, 128) !important;
  text-decoration: none;
  width: 100%;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  font-size: 30px !important;
  font-family: GlacialInDifferenceBold;
}

.container-404 .ant-card-head {
  border: none;
}

.not-found-screen .ant-card-bordered .ant-card-body {
  padding: 0px !important;
}

.not-found-screen .margin-bottom-12 {
  margin-bottom: -15px !important;
}

.not-found-screen .ant-card-meta-title {
  font-family: 'GlacialInDifferenceBold' !important;
}

.not-found-title {
  font-size: 40px;
  color: rgb(29, 76, 128) !important;
  margin-left: -3px !important;
  font-family: raleway;
  font-weight: 400 !important;
  text-align: left;
  line-height: 0.8 !important;
  margin-top: 0px !important;
}

.not-found-screen hr {
  margin-top: 20px;
  margin-bottom: 2px;
}

.not-found-screen h5 {
  text-transform: none;
  font-size: 25px !important;
  margin-bottom: 0px;
}

.not-found-screen p {
  text-align: left;
  font-size: 20px;
  padding: 0.5rem;
  margin-bottom: 0px !important;
  padding-left: 0px;
  padding-right: 0px;
}

.not-found-hyperlinks {
  color: #294c7c;
  font-weight: 600 !important;
}

.not-found-available-careers {
  width: 335px;
  border: 1px solid #294c7c;
  border-radius: 24px;
  height: 84px;
  margin-top: 30px;
  background: #294c7c;
}

.not-found-available-careers h3 {
  margin: 10px !important;
}

.not-found-screen .link {
  text-align: center;
  cursor: pointer;
}

.not-found-foot-note {
  font-size: 16px !important;
  padding: 0 !important;
}

@media (max-width: 480px) {
  .not-found-card {
    width: 100% !important;
  }

  .not-found-title {
    font-size: 30px !important;
    margin-bottom: -5px !important;
  }

  .not-found-screen .margin-bottom-12 {
    margin-bottom: 15px !important;
  }
  .not-found-screen {
    width: 100%;
    padding: 25px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
  }

  .not-found-screen p {
    font-size: 19px !important;
    text-align: left;
    margin-top: 0px;
    line-height: 1.4;
    font-weight: 700;
    line-height: 1.25;
  }
  .container-404 .logo-holder img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .not-found-card {
    width: 95% !important;
  }

  .not-found-title {
    font-size: 35px !important;
    margin-bottom: 5px !important;
    line-height: 0.5 !important;
  }

  .not-found-screen .margin-bottom-12 {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 1023px) {
  .container-404 .container-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .container-404 .get-quote, .container-404 .customer-lnks {
    min-width: 100%;
  }
  .container-404 .desktop-divider {
    display: none;
  }

  .container-404 .button-cell-holder {
    margin-top: 12px;
  }

  .container-404 .logo-holder {
    width: 300px;
    margin: 40px auto 30px;
  }

  .container-404 span.sub-header-title {
    font-size: 20px;
  }

  .container-404 .mobile-divider-container {
    display: block;
  }

  .container-404 .logo-holder img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
  .container-404 .ButtonCell {
    width: 85px!important;
  }
  .container-404 .get-quote {
    order: 2;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .not-found-card {
    width: 85% !important;
  }

  .not-found-title {
    font-size: 40px !important;
  }
}

@media (max-width: 560px) {
  .not-found-title {
    line-height: 2 !important;
  }
}
