.pdfLink {
    content: url(https://assets.cure.com/img/main-site/pdfIcon.png);
    width: 40px;
    height: 40px;
}

.postal-bold {
    font-family: GlacialInDifferenceBold;
}

.ul-hyphen {
    list-style-type: none;
    margin-left: 32px;
    margin-bottom: 8px;
}

.ul-hyphen li:before {
    content: '\2014';
    position: absolute;
    margin-left: -20px;
}

.text-center {
    text-align: center;
    width: 100%;
}

.roundy-chatnow {
    width: 190px;
    height: 150px;
    background-image: url('../images/roundy-chatnow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 100000;
    display: block;
    cursor: pointer;
}

::placeholder {
    color: rgba(0, 0, 0) !important;
    font-family: GlacialIndifference !important;
    font-size: 1.2em !important;
    font-size: 19px !important;
}

@media(max-width: 700px) {
    .roundy-chatnow {
        transform: scale(0.8);
        bottom: -18px;
        right: -18px;
    }
}

@media(max-width: 550px) {
    .roundy-chatnow {
        transform: scale(0.5);
        bottom: -30px;
        right: -30px;
    }
}