.accessibility .cure-main-container .alert-nav {
  background: #294c7c !important;
  z-index: 1;
  width: 100%;
  padding: 0 10% !important;
}

.accessibility .cure-main-container .menu-nav,
.accessibility .cure-main-container .menu-nav-mobile {
  background: rgb(55, 105, 152);
  z-index: 1;
  width: 100%;
  padding: 0px 12px;
  font-family: GlacialIndifferenceBold;
}

.accessibility .cure-main-container .secondary-nav {
  background: rgb(48, 78, 97);
  z-index: 1;
  width: 100%;
  padding: 0px 3px 0px 4px;
  font-family: GlacialIndifferenceBold;
}

.accessibility .phone {
  margin-right: 0px;
  transform: rotate(120deg);
}

.accessibility .credit-card {
  margin-right: 6px;
}

.accessibility .alert-nav-img {
  width: 143px;
  right: 0rem;
  position: absolute;
  top: 3.3em;
}

.accessibility .alert-title {
  display: initial;
}

.accessibility .alert-nav .logo {
  width: 52px;
}

.accessibility .menu-nav-mobile img,
.accessibility .menu-nav img {
  filter: brightness(0) invert(1);
  width: 152px;
  display: block;
  margin-top: 9px;
  margin-bottom: 3px;
}

.accessibility .menu-nav-mobile .menu-container,
.menu-nav .menu-container {
  background: transparent;
  color: #fff;
  line-height: 48px !important;
}

.accessibility .menu-nav-mobile .ant-menu-horizontal,
.accessibility .menu-nav .ant-menu-horizontal {
  border-bottom: none !important;
}

.accessibility .menu-nav-mobile .ant-menu-title-content,
.accessibility .menu-nav .ant-menu-title-content {
  text-transform: uppercase;
  font-size: 15px;
}

.accessibility .menu-nav-mobile .ant-menu-title-content a,
.menu-nav .ant-menu-title-content a {
  font-family: 'GlacialIndifferenceBold';
}

.accessibility .menu-nav-mobile .ant-row,
.menu-nav .ant-row {
  height: inherit !important;
}

.accessibility .menu-nav-mobile .ant-row .ant-col,
.menu-nav .ant-row .ant-col {
  height: 100% !important;
}

.accessibility .expanol-link-container {
  float: right;
}

.accessibility .menu-container li {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 64px;
}

.accessibility .drawer-menu {
  background: transparent;
  color: #fff;
  border: none;
}

.accessibility .ant-drawer a {
  color: #000 !important;
}

.accessibility .start-quote span,
.accessibility .sign-in span {
  vertical-align: middle;
  font-family: 'GlacialIndifference';
  line-height: 31px;
}

.accessibility .expanol-link-container .start-quote,
.accessibility .expanol-link-container .sign-in,
.accessibility .expanol-link-container button {
  margin-right: 0px;
  background: transparent;
  border: none;
  color: #fff;
  font-family: 'GlacialIndifference';
  vertical-align: middle;
}

.accessibility .expanol-link-container a.ant-typography {
  font-size: 15px;
}

.accessibility .ant-menu-inline {
  background: #fff;
  color: #000;
}

.accessibility .ant-menu-submenu-title:hover {
  color: black !important;
}

.accessibility .ant-drawer-body span {
  color: #000 !important;
}

.accessibility .ant-menu-submenu-active div:first-child {
  background-color: #294c7c;
  color: #fff !important;
}

.accessibility .ant-menu-submenu-active div:first-child span {
  background-color: #294c7c;
  color: #fff !important;
}

.accessibility .ant-menu-submenu-title:after {
  display: none;
}

.accessibility .ant-menu-light .ant-menu-submenu-title {
  padding-left: 12px !important;
}

.accessibility .ant-menu-inline.ant-menu-root .ant-menu-item {
  padding-left: 12px !important;
}

.accessibility .ant-menu-submenu-active .ant-menu-submenu-arrow {
  color: #fff !important;
}

.accessibility .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  left: calc(100% - 250px);
}

.accessibility .ant-drawer-body {
  padding-top: 48px;
  font-family: GlacialInDifference;
}

li a {
  color: #000;
}

.accessibility .phone-numbers {
  display: block;
}

.accessibility .credit-card {
  margin-right: 6px;
}

.accessibility .ant-drawer-body ul.ant-menu-root {
  margin-top: 18px !important;
}

.accessibility .ant-menu-submenu-active div:first-child,
.accessibility .ant-menu-submenu-active div:first-child span {
  background-color: #294c7c;
}

@media (max-width: 999px) {
  .accessibility .alert-title {
    font-size: 20px !important;
  }

  .accessibility .alert-nav .logo {
    width: 46px;
  }
}

@media (max-width: 880px) {
  .accessibility .alert-title {
    font-size: 16px !important;
  }

  .accessibility .alert-nav .logo {
    width: 52px;
  }
}

@media (max-width: 767px) {
  .accessibility .secondary-nav {
    display: block;
  }

  .accessibility .alert-title {
    font-size: 14px !important;
    margin: 12px !important;
  }

  .accessibility .alert-nav .logo {
    width: 32px;
  }

  .accessibility .cure-main-container .alert-nav {
    padding: 0 5% !important;
  }
}

@media (max-width: 590px) {
  .accessibility .cure-main-container .alert-nav {
    padding: 0px !important;
  }

  .accessibility .secondary-nav {
    display: block;
  }

  .accessibility .alert-title {
    font-size: 13px !important;
    margin: 12px !important;
  }

  .accessibility .alert-nav .logo {
    width: 28px;
  }

  .accessibility .alert-nav h3 {
    letter-spacing: 0px;
  }
}

@media (max-width: 365px) {
  .cure-main-container .alert-title {
    margin: 0px !important;
  }
}

@media (max-width: 481px) {
  .accessibility .alert-nav .mi-header {
    display: none !important;
  }
}

@media (max-width: 1025px) {
  .accessibility .menu-nav {
    display: block;
  }

  .accessibility .menu-nav-mobile {
    display: block;
  }

  .accessibility .secondary-nav {
    display: block;
  }

  .accessibility .ant-menu-submenu-active div:first-child,
  .accessibility .ant-menu-submenu-active div:first-child span {
    background-color: #294c7c;
  }

  .accessibility .expanol-link-container .menu-icon,
  .accessibility .expanol-link-container .menu-icon:hover {
    margin-right: 0;
    background-color: transparent !important;
    border-color: transparent !important;
    color: #fff !important;
  }
}

@media (max-width: 460px) {
  .accessibility .rsa {
    display: none;
  }
}

@media (max-width: 577px) {
  .accessibility .space-column {
    display: none !important;
  }

  span.contactNumber {
    display: none !important;
  }
}