.show-quick-service .selected-qs {
  margin-bottom: 0px !important;
  margin-left: 0;
  text-align: center !important;
  margin-top: 6px;
  font-weight: 400;
  font-style: normal;
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  width: 100%;
  color: rgb(0, 0, 0);
  line-height: 1.58em;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  font-size: 21px !important;
  font-family: GlacialInDifference;
}

.show-quick-service .ant-input:placeholder-shown {
  font-size: 1.2em !important;
}

.show-quick-service .error-message {
  color: #ff4d4f;
  margin-left: 0px;
  margin-top: 4px;
}

.show-quick-service .blue-message-bold {
  color: #1d4c80 !important;
  margin-left: 0px;
  margin-top: 4px;
  font-family: GlacialInDifferenceBold;
}

.show-quick-service .ant-btn[disabled],
.show-quick-service .ant-btn[disabled]:active,
.show-quick-service .ant-btn[disabled]:focus,
.show-quick-service .ant-btn[disabled]:hover {
  color: rgba(255, 255, 255, 0.5);
  border-color: #d9d9d9;
  background: #ff8000;
  text-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}

.show-quick-service .success-message {
  color: green;
  margin-left: 0px;
  margin-top: 4px;
}

.show-quick-service .input-text {
  border: 1px solid rgb(55, 105, 152) !important;
}

.show-quick-service input::placeholder {
  color: #535353;
  opacity: 0.8;
}

.show-quick-service .ant-spin {
  margin-top: 4px;
}

.show-quick-service .float-left {
  float: left;
}

.show-quick-service .blue-message {
  color: #294c7c;
  margin-left: 0px;
  margin-top: 4px;
}

@media (max-width: 430px) {
  .show-quick-service .ant-card-extra {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
  }
  .my-account-screen .show-login .ant-card-head-title {
    padding-bottom: 10px;
  }
  .show-quick-service .ant-form-horizontal {
    margin-top: 24px;
  }
}
