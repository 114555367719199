.header-hero {
  height: 300px;
  /* background-size: cover; */
  background-position: top;
  z-index: 1;
  position: relative;
}

.header-hero .bg-container {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.header-hero .bg-container img {
  width: 100%;
  height: 100%;
  object-position: center;
  -o-object-position: center;
  display: block;
  max-width: 100%;
  object-fit: cover;
}

.header-hero.object-fit-unset .bg-container img {
  object-fit: unset !important;
}

.header-hero .text-inner-wrapper {
  z-index: 1;
  position: relative;
  margin: auto;
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.header-hero .text-inner-wrapper .title {
  color: #fff;
  font-size: 40px;
  color: white !important;
  line-height: 1.12em;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  width: inherit;
  padding: 0;
  font-family: raleway;
  font-weight: 900 !important;
  margin-left: 0px !important;
  margin-bottom: 12px !important;
}

@media (max-width: 480px) {
  .header-hero .text-inner-wrapper .title {
    margin-left: 20px !important;
  }
  
  .header-hero {
    height: 188px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .header-hero .text-inner-wrapper {
    padding-left: 24px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header-hero .text-inner-wrapper .title {
    margin-left: 42px !important;
  }
  .header-hero .text-inner-wrapper {
    margin-left: 0px!important;
  }
}
