.show-login .ant-form-horizontal {
  margin-top: 26px;
}

.show-login .ant-input:placeholder-shown {
  font-size: 1.2em !important;
}

.show-login .subtext {
  text-align: center !important;
}

.show-login .input-text {
  border: 1px solid rgb(55, 105, 152) !important;
}

.show-login input::placeholder,
.ant-input-password-icon {
  color: #535353 !important;
  opacity: 0.8;
}

.show-login .error-message {
  color: #ff4d4f;
  margin-left: 0px;
  margin-top: 4px;
}

.show-login .ant-spin {
  margin-top: 4px;
}

.show-login .float-left {
  float: left;
}
.show-login .success-message {
  color: green;
  margin-left: 0px;
  margin-top: 4px;
}

.show-login .blue-message {
  color: #294c7c;
  margin-left: 0px;
  margin-top: 4px;
}

.show-login .blue-message {
  color: #294c7c;
  margin-left: 0px;
  margin-top: 4px;
}

.show-login .blue-message-bold {
  color: #1d4c80 !important;
  margin-left: 0px;
  margin-top: 4px;
  font-family: GlacialInDifferenceBold;
}

.show-login .blue-message a {
  font-family: GlacialInDifferenceBold;
  color: #294c7c;
  margin-left: 0px;
  margin-top: 4px;
}

.show-login .ant-btn[disabled],
.show-login .ant-btn[disabled]:active,
.show-login .ant-btn[disabled]:focus,
.show-login .ant-btn[disabled]:hover {
  color: rgba(255, 255, 255, 0.5);
  border-color: #d9d9d9;
  background: #ff8000;
  text-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}

.show-login .bold-text {
  font-family: GlacialInDifferenceBold;
}

.show-login
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #ff4d4f !important;
}

@media (max-width: 430px) {
  .show-login .ant-card-extra {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
  }
}

.subtext {
  text-align: center !important;
}

.input-text {
  border: 1px solid rgb(55, 105, 152) !important;
}

input::placeholder,
.ant-input-password-icon {
  color: #535353 !important;
  opacity: 0.8;
}

.error-message {
  color: #ff4d4f;
  margin-left: 0px;
  margin-top: 4px;
}

.ant-spin {
  margin-top: 4px;
}

.float-left {
  float: left;
}
.success-message {
  color: green;
  margin-left: 0px;
  margin-top: 4px;
}

.blue-message {
  color: #294c7c;
  margin-left: 0px;
  margin-top: 4px;
}

.blue-message {
  color: #294c7c;
  margin-left: 0px;
  margin-top: 4px;
}

.blue-message-bold {
  color: #1d4c80 !important;
  margin-left: 0px;
  margin-top: 4px;
  font-family: GlacialInDifferenceBold;
}

.blue-message a {
  font-family: GlacialInDifferenceBold;
  color: #294c7c;
  margin-left: 0px;
  margin-top: 4px;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  color: rgba(255, 255, 255, 0.5);
  border-color: #d9d9d9;
  background: #ff8000;
  text-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}

.bold-text {
  font-family: GlacialInDifferenceBold;
}

  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #ff4d4f !important;
}

@media (max-width: 430px) {
  .ant-card-extra {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
  }
}
